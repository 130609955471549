import React from 'react';
import { graphql } from 'gatsby';
import { useDispatch } from 'react-redux';

import {
    setPeopleCardModalIsOpen,
    setPeopleCardModalContent,
} from '@om/redux/actions/publicWeb/peopleCardModal';

import { OmPeopleCard } from '@om/component-library-react';
import OmButton from './forms/OmButton';

const PeopleCard = ({
    peopleCardContent,
    isLandscape,
    className,
    slot
}) => {
    const dispatch = useDispatch();

    if (!peopleCardContent) {
        return '';
    };

    const {
        uid,
        background_image_url,
        overlay_image_url,
        date_of_birth,
        full_name,
        people_card_title,
        people_card_division,
        people_card_content,
        facebook_url,
        email_url,
        twitter_url,
        you_tube_url,
        linkedin_url,
        primary_button_text,
        primary_button_url,
        people_card_modal_text,
        open_links_in_new_tab,
        open_tab,
    } = peopleCardContent;

    function peopleModalData() {
        const peopleModalData = {
            name: '',
            age: null,
            content: '',
        };
        if (date_of_birth) {
            peopleModalData.age = Math.abs(
                new Date(
                    Date.now() - new Date(date_of_birth).getTime()
                ).getUTCFullYear() - 1970
            );
        }
        peopleModalData.name = full_name;
        peopleModalData.content = people_card_modal_text;
        return peopleModalData;
    }

    const openModal = () => {
        let openModalData = peopleModalData();
        // Set up the data for the modal
        dispatch(setPeopleCardModalContent(openModalData));
        // Open Modal
        dispatch(setPeopleCardModalIsOpen(true));
    }

    return (
        <OmPeopleCard
            id={uid}
            className={className}
            slot={slot}
            dateOfBirth={date_of_birth}
            fullName={full_name}
            peopleCardTitle={people_card_title}
            peopleCardDivision={people_card_division}
            peopleCardContent={people_card_content}
            facebookUrl={facebook_url.href}
            emailUrl={email_url.href}
            twitterUrl={twitter_url.href}
            youTubeUrl={you_tube_url.href}
            openLinksInNewTab={open_links_in_new_tab}
            linkedinUrl={linkedin_url.href}
            isLandscape={isLandscape}
        >
            {
                overlay_image_url && <div slot="people-fg-image" style={{ backgroundImage: `url('${overlay_image_url.url}')` }} />
            }
            {
                background_image_url && <div slot="people-bg-image" style={{ backgroundImage: `url('${background_image_url.url}')` }} />
            }
            <div slot="primary-button">
                {people_card_modal_text ?
                    <OmButton
                        type="text"
                        size="small"
                        text={primary_button_text}
                        onClick={() => openModal()}
                    />
                    : ''}
                {primary_button_url === '' ?
                    <OmButton
                        type="text"
                        size="small"
                        url={primary_button_url}
                        text={primary_button_text}
                        actionType="Url"
                        openTab={open_tab}
                    /> : ''
                }
            </div>
        </OmPeopleCard>
    )

}
export const pageQuery = graphql`
fragment PeopleCard on Contentstack_people_card {
    uid
    background_image_url {
    	...File
    }
    overlay_image_url {
        ...File
    }
    date_of_birth
    full_name
    people_card_title
    people_card_division
    people_card_content
    people_card_category
    facebook_url {
    	...Link
    }
    email_url {
    	...Link
    }
    twitter_url {
    	...Link
    }
    you_tube_url {
    	...Link
    }
    linkedin_url {
        ...Link
    }
    primary_button_text
    primary_button_url {
    	...Link
    }
    primary_button_action_type
    people_card_modal_text
    open_links_in_new_tab
    open_tab
}
`;

export default PeopleCard;
