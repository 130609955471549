import WebComponentsConfig from '../components/WebComponentsConfig';
import React from 'react';
import MainLayout from '../layouts/MainLayout';
import { graphql } from 'gatsby';
import PageModularBlock from '../components/PageModularBlock';
import Header from '../components/header/Header';
import ParallaxContainer from '../components/ParallaxContainer';
import StickyFooter from '../components/StickyFooter';
import {
    OmPage,
    OmWebShareLinks,
    Om1ColLayout
} from '@om/component-library-react';

const ModularPage = ({ data, location }) => {
    const {
        theme,
        uid,
        locale,
        page_head_tags,
        footer,
        paralax_container,
        sticky_footer,
        page_background_url,
        signup_and_download,
        header,
        modular_blocks,
        include_web_share,
        disclaimer_text
    } = data.contentstackModularPage;

    const primaryArticleCards = data.primaryArticleCards.edges.sort((a, b) => {
        let da = new Date(a.node.publish_date),
            db = new Date(b.node.publish_date);
        return da - db;
    }) || [];
    const secondaryArticleCards = data.secondaryArticleCards.edges.sort((a, b) => {
        let da = new Date(a.node.publish_date),
            db = new Date(b.node.publish_date);
        return da - db;
    }) || [];
    const refinedArticleCards = [...primaryArticleCards, ...secondaryArticleCards];

    return (
        <WebComponentsConfig themeContent={theme}>
            <OmPage>
                <div slot="page-content">
                    <MainLayout
                        metaContent={page_head_tags}
                        header={
                            <Header headerContent={header[0]}
                                pageBackgroundUrl={page_background_url}
                                location={location} />
                        }
                        footerContent={footer && footer[0]}
                        signupContent={signup_and_download && signup_and_download[0]}
                        contentType="modular_page"
                        entryUid={uid}
                        locale={locale}
                        location={location}>
                        <ParallaxContainer parallaxContainerContent={paralax_container && paralax_container[0]} />
                        {modular_blocks ? modular_blocks.map((value) => {
                            return <PageModularBlock key={value.id} modularBlock={value} refinedArticleCards={refinedArticleCards} />;
                        }) : ''}
                        {
                            include_web_share &&
                            <Om1ColLayout
                                className="block extra-large-margin-top extra-large-margin-bottom"
                                narrow={true}
                            >
                                <OmWebShareLinks />
                            </Om1ColLayout>
                        }
                        {
                            disclaimer_text &&
                            <Om1ColLayout
                                className="block extra-large-margin-top extra-large-margin-bottom"
                                narrow={true}
                            >
                                <p className="no-margin muted" dangerouslySetInnerHTML={{ __html: disclaimer_text }} />
                            </Om1ColLayout>
                        }
                        <StickyFooter stickyFooterContent={sticky_footer && sticky_footer[0]} />
                    </MainLayout>
                </div>
            </OmPage>
        </WebComponentsConfig>
    );
};

export const pageQuery = graphql`
query ModularPageQuery(
    $id: String!,
    $articleCardFilterItemsPrimary: [String]!,
    $articleCardFilterItemsSecondary: [String]!
) {
    contentstackModularPage(id: { eq: $id }) {
        id
        uid
        locale
        theme {
            ...Theme
        }
        page_background_url {
            ...File
        }
        page_head_tags {
            ...PageHeadTags
        }
        header {
            __typename
            ...HeaderWithBreadcrumbs,
            ...HeaderWithBreadcrumbsV2,
            ...SegmentLandingHeader,
            ...SegmentLandingHeaderV2,
            ...HomePageHeader,
            ...ArticleLandingHeader
        }
        modular_blocks {
            ...ModularBlock
        }
        paralax_container {
            ...ParalaxContainer
        }
        sticky_footer {
            __typename
            ...FloatingFooterBar,
            ...LiveChat
        }
        signup_and_download {
            ...SignupAndDownload
        }
        footer {
            __typename
            ...Footer,
            ...FooterV2
        }
        include_web_share
        disclaimer_text
    }

    primaryArticleCards: allContentstackRefinedArticleCard (filter: {
        card_filter_item: {
            elemMatch: {
                uid: { in: $articleCardFilterItemsPrimary }
            }
        }
    }) {
        edges {
            node {
                ...RefinedArticleCard
            }
        }
    }

    secondaryArticleCards: allContentstackRefinedArticleCard (filter: {
        secondary_filter_items: {
            elemMatch: {
                uid: { in: $articleCardFilterItemsSecondary }
            }
        }
    }) {
        edges {
            node {
                ...RefinedArticleCard
            }
        }
    }
}
`;

export default ModularPage;
