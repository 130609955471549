import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import {
    getVisibleBusinessProducts,
    getBusinessProductsData,
    getParams
} from '@om/redux/selectors/publicWeb/businessProducts';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import RefinedProductCard from './RefinedProductCard';
import {
    fetchBusinessProducts,
    setParams
} from '@om/redux/actions/publicWeb/businessProducts';
import Pagination from './Pagination';
import {
    OmProductCardFilter,
    OmCheckBox,
} from '@om/component-library-react';

//let renderKey = 0;
let cardsPerPage = 9;
let currentPageNumber = 1;
let checkedUIDs = [];

function getCardClass(index) {
    let cardclass = 'hydrated';
    if (index === 0 || index % 3 === 0) {
        cardclass += ' new-row-xl';
    }
    if (index === 0 || index % 2 === 0) {
        cardclass += ' new-row-l';
    }
    return cardclass;
}

function scrollUp(){
    const productFilterElem = document.getElementById('product-cards');
    if(productFilterElem) {
        const scrolly = productFilterElem.getBoundingClientRect().y + window.scrollY;
        window.scrollTo({ top: scrolly - 150});
    }
}

const ProductCardFilter = ({
    productCardFilterContent,
}) => {

    const {
        uid,
        has_boundary,
        reset_button_text,
        top_line,
        heading,
        sub_title,
        caption,
        card_filter_items,
        is_extended_filter
    } = productCardFilterContent;

    function updateSearchFields(filterItem) {
        let checkUIDs = [];
        const take = cardsPerPage;

        if (filterItem === 'all') {
            card_filter_items.map((card_filter_item ,index) => {
                const uid = card_filter_item.uid;
                let allButtonElement = document.getElementById('allButton');
                var element = document.getElementById(index);
                element.checked = false;
                if (allButtonElement.checked) checkUIDs.push(uid);
            });
        } else {
            card_filter_items.map((card_filter_item,index)=> {
                document.getElementById('allButton').checked = false;
                const uid = card_filter_item.uid;
                var element = document.getElementById(index);
                if (element.checked) checkUIDs.push(uid);
            });
        }
        if (checkUIDs.length === 0) {
            card_filter_items.map((card_filter_item, index) => {
                const uid = card_filter_item.uid;
                document.getElementById('allButton').checked = true;
                var element =  document.getElementById(index);
                element.checked = false;
                checkUIDs.push(uid);
            });
        }
        checkedUIDs = checkUIDs;
        dispatch( setParams({ uid: checkUIDs, take, skip: 0 }));
        dispatch( fetchBusinessProducts());
        scrollUp();
    }

    function onPaginate(pageNumber) {
        currentPageNumber = pageNumber;
        const skip = cardsPerPage * (pageNumber - 1);
        const take = cardsPerPage;
        dispatch(setParams({ uid: checkedUIDs, take: take, skip: skip }));
        dispatch(fetchBusinessProducts());
        scrollUp();
    }

    const dispatch = useDispatch();
    const {
        products,
        productData
    } = useSelector(
        createStructuredSelector({
            products : getVisibleBusinessProducts,
            productData : getBusinessProductsData,
            params : getParams
        })
    );

    useEffect(() => {
        if(card_filter_items && card_filter_items.length > 0) {
            card_filter_items.map((card, index) => {
                checkedUIDs.push(card.uid);
            });
            dispatch( setParams({ uid: checkedUIDs, take: 9, skip: 0 } ));
            dispatch( fetchBusinessProducts());
        }
    }, []);

    if (!productCardFilterContent) {
        return '';
    }

    return (
        <section>
            <OmProductCardFilter
                id={uid}
                hasBoundary={has_boundary}
                resetButtonText={reset_button_text}
            >
                { top_line ? <span slot="top-line" dangerouslySetInnerHTML={{ __html: top_line }} /> : ''}
                { heading ? <span slot="heading" dangerouslySetInnerHTML={{ __html: heading }} /> : ''}
                { sub_title ? <span slot="sub_title" dangerouslySetInnerHTML={{ __html: sub_title }} /> : ''}
	            { caption ? <span slot="caption" dangerouslySetInnerHTML={{ __html: caption }} /> : ''}
                {
                    <div slot="product-cards" id="product-cards">
                        <div className="filter-items-container">
                            <OmCheckBox
                                id="allButton"
                                uid={uid}
                                className ="filter-button"
                                isButtonCheckbox={true}
                                text="ALL"
                                checked={true}
                                onOnCheckBoxChanged ={() => updateSearchFields('all')}
	                        />
                            {
                                card_filter_items && card_filter_items.length > 0 && card_filter_items.map((cardFilterItem, index) =>{
                                    return(
                                        <OmCheckBox
                                            className="filter-button"
                                            id={index}
                                            uid={cardFilterItem.uid}
                                            hasIcon={true}
                                            isButtonCheckbox={true}
                                            key={cardFilterItem.uid}
                                            text={cardFilterItem.check_box_text}
                                            onOnCheckBoxChanged ={() => updateSearchFields(cardFilterItem.uid)}
                                        />
                                    );
                                })
                            }
                        </div>
                        {
                            products ? products.map((productCard, index) => {
                                return (
                                    <RefinedProductCard
                                        key={productCard.uid}
                                        className={getCardClass(index)}
                                        refinedProductCardContent={productCard}
                                        isExtended={is_extended_filter}
                                    />
                                );
                            }) : ''
                        }
                        <Pagination
                            resultsPerPage={cardsPerPage}
                            cardCount={productData.count}
                            currentPage={currentPageNumber}
                            paginationLeftClick={event => onPaginate(event.detail)}
                            paginationRightClick={event => onPaginate(event.detail)}
                            paginationItemClick={event => onPaginate(event.detail)}
                        />
                    </div>
                }
            </OmProductCardFilter>
        </section>
    );
};

export const pageQuery = graphql`
    fragment ProductCardFilterItem on Contentstack_product_card_filter_item {
        uid
        check_box_text
    }

    fragment ProductCardFilter on Contentstack_product_card_filter {
        uid
        title
        heading
        use_heading_as_h1
        top_line
        sub_title
        caption
        reset_button_text
        has_boundary
        is_extended_filter
        card_filter_items {
            ...ProductCardFilterItem
        }
    }
`;

export default ProductCardFilter