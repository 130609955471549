import React from 'react';
import ArticleCardFilter from './ArticleCardFilter';
import ProductCardFilter from './ProductCardFilter';
import PeopleCardFilter from './PeopleCardFilter';

const Filter = ({ filterContent, refinedArticleCards }) => {
    if (!filterContent)
        return '';

    if (filterContent.__typename === 'Contentstack_article_card_filter')
        return (<ArticleCardFilter articleCardFilterContent={filterContent} refinedArticleCards={refinedArticleCards} />);

    if (filterContent.__typename === 'Contentstack_product_card_filter')
        return (<ProductCardFilter productCardFilterContent={filterContent} />);

    if (filterContent.__typename === 'Contentstack_people_card_filter')
        return (<PeopleCardFilter peopleCardFilterContent={filterContent} />);

    return '';
}

export default Filter;
