import React, {useEffect} from 'react';
import { graphql } from 'gatsby';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import MainNavigation from './MainNavigation'
import Breadcrumbs from '../Breadcrumbs'
import { handleOmButtonClick } from '../forms/OmButton';
import { OmHeaderWithBreadcrumbs } from '@om/component-library-react';
import { getIsFormOpenAndPrepopulated} from '@om/redux/selectors/publicWeb/callMeBack';
import './HeaderWithBreadcrumbs.scss';

const HeaderWithBreadcrumbsV2 = ({
    headerWithBreadcrumbsContent,
    slot,
    backgroundImage,
    location
}) => {
    const dispatch = useDispatch();
    let cmbData;

    if(headerWithBreadcrumbsContent){
        let isPrimaryBtnCmb = headerWithBreadcrumbsContent?.hero_banner_primary_button?.display_text
        === 'CALL ME BACK';
        cmbData = isPrimaryBtnCmb ? headerWithBreadcrumbsContent?.hero_banner_primary_button 
            : headerWithBreadcrumbsContent?.hero_banner_secondary_button;
    }

    const {
        isPrepopulatedCmb
    } = useSelector(
        createStructuredSelector({
            isPrepopulatedCmb: getIsFormOpenAndPrepopulated,

        })
    );

    useEffect(() => {
        if(isPrepopulatedCmb && cmbData)
            handleOmButtonClick(cmbData, dispatch, isPrepopulatedCmb, 'HeaderWithBreadcrumbsV2');
    }, [
        isPrepopulatedCmb,
        cmbData
    ]);

    if(!headerWithBreadcrumbsContent) {
        return '';
    }

    const {
        main_navigation,
        display_text,
        call_text,
        call_number,
        call_left_text,
        bg_img_url,
        hero_banner_header,
        hero_banner_caption,
        hero_banner_primary_button,
        hero_banner_secondary_button,
    } = headerWithBreadcrumbsContent;

    return (
        <OmHeaderWithBreadcrumbs
            slot={slot}
            onButtonAction={(e) => {
                handleOmButtonClick(e, dispatch, isPrepopulatedCmb);
            }}
            displaytext={display_text ? display_text : ''}
            callText={call_text ? call_text : ''}
            callNumber={call_number ? call_number : ''}
            heroBannerButtonText={hero_banner_primary_button && hero_banner_primary_button.display_text}
            heroBannerButtonActionObject={hero_banner_primary_button && hero_banner_primary_button.action && JSON.stringify(hero_banner_primary_button.action[0])}
            heroBannerSecondaryButtonText={hero_banner_secondary_button && hero_banner_secondary_button.display_text}
            heroBannerSecondaryButtonActionObject={hero_banner_secondary_button && hero_banner_secondary_button.action && JSON.stringify(hero_banner_secondary_button.action[0])}
        >
            {
                call_left_text && call_left_text !== '<br />' && <text slot="click-to-call-left" dangerouslySetInnerHTML={{__html: call_left_text }} />
            }
            {
                backgroundImage && <div slot="page-bg-image" style={{ backgroundImage: `url('${backgroundImage}')` }}/>
            }
            {
                bg_img_url && bg_img_url.gatsbyImageData ? (
                    <picture slot="hero-image">
                    <source
                        type={bg_img_url?.gatsbyImageData.images.sources[0].type}
                        srcSet={bg_img_url?.gatsbyImageData.images.sources[0].srcSet}
                        sizes={bg_img_url?.gatsbyImageData.images.sources[0].sizes}
                        media="(min-width: 786px)"
                    />
                    <img
                        height={bg_img_url?.gatsbyImageData.height}
                        src={bg_img_url?.gatsbyImageData.images.fallback?.src}
                        decoding="async"
                        loading="eager"
                        alt="hero banner with breadcrumbs image"
                        />
            </picture>) : ''
            }
            {
                hero_banner_header && <span slot="hero-banner-header" dangerouslySetInnerHTML={{__html: hero_banner_header}} />
            }
            {
                hero_banner_caption && <span slot="hero-banner-caption" dangerouslySetInnerHTML={{__html: hero_banner_caption}}/>
            }
            {main_navigation && main_navigation.length > 0 &&
                <MainNavigation slot="main-navigation" mainNavigationContent={main_navigation[0]} location={location}/>
            }
            <Breadcrumbs slot="breadcrumbs" location={location}/>
        </OmHeaderWithBreadcrumbs>
    );
};

export const pageQuery = graphql`
fragment HeaderWithBreadcrumbsV2 on Contentstack_header_with_breadcrumbs_v2 {
    main_navigation {
        ...MainNavigation
    }
    display_text
    call_text
    call_number
    call_left_text
    bg_img_url {
        gatsbyImageData(
            placeholder: BLURRED
            formats: [AUTO, WEBP]
            layout: CONSTRAINED
            height: 540
        )
    }
    hero_banner_header
    hero_banner_caption
    hero_banner_primary_button {
        ...HeaderWithBreadcrumbsPrimaryButton
    }
    hero_banner_secondary_button {
        ...HeaderWithBreadcrumbsSecondaryButton
    }
}
`;

export default HeaderWithBreadcrumbsV2;
