import React, {useEffect} from 'react';
import { graphql } from 'gatsby';
import MainNavigation from './MainNavigation'
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { handleOmButtonClick } from '../forms/OmButton';
import { OmSegmentLandingHeader } from '@om/component-library-react';
import { getIsFormOpenAndPrepopulated} from '@om/redux/selectors/publicWeb/callMeBack';
import './SegmentLandingHeader.scss';

import {
    toggleComplaintForm,
    toggleArbitrationForm
} from '@om/redux/actions/customerComplaint/complaintArbitration';

const SegmentLandingHeader = ({
    segmentLandingHeaderContent,
    slot,
    backgroundImage,
    location
}) => {
    const dispatch = useDispatch();
    let cmbData;

    if(segmentLandingHeaderContent){
        cmbData = segmentLandingHeaderContent?.call_me_back[0] ? segmentLandingHeaderContent?.call_me_back
            : segmentLandingHeaderContent?.call_me_back_secondary;
    }

    const {
        isPrepopulatedCmb
    } = useSelector(
        createStructuredSelector({
            isPrepopulatedCmb: getIsFormOpenAndPrepopulated,

        })
    );

    useEffect(() => {
        if(isPrepopulatedCmb && cmbData)
            handleOmButtonClick(cmbData, dispatch, isPrepopulatedCmb, 'SegmentLandingHeader');
    }, [
        isPrepopulatedCmb,
        cmbData
    ]);

    if(!segmentLandingHeaderContent) {
        return '';
    }

    const {
        displaytext,
        call_text,
        call_number,
        call_left_text,
        bg_img_url,
        main_navigation,
        banner_header,
        banner_content,
        hero_banner_button_text,
        hero_banner_button_url,
        hero_banner_button_action_type,
        hero_banner_secondary_button_text,
        hero_banner_secondary_button_url,
        hero_banner_secondary_button_action_type,
        quick_links_header,
        quick_links_mobile_header,
        quick_links_item_1,
        quick_links_url_1,
        quick_links_item_2,
        quick_links_url_2,
        quick_links_item_3,
        quick_links_url_3,
        call_me_back ,
        call_me_back_secondary,
        open_tab
    } = segmentLandingHeaderContent;

    return (
        <OmSegmentLandingHeader
            slot={slot}
            onButtonClicked={(e) => {
                if(e.detail === 'Complaint') {
                    dispatch(toggleComplaintForm());
                } else if (e.detail === 'Arbitration') {
                    dispatch(toggleArbitrationForm());
                }
            }}
            displaytext={displaytext}
            callText={call_text}
            callNumber={call_number}
            heroBannerButtonText={hero_banner_button_text}
            heroBannerButtonUrl={hero_banner_button_url ? hero_banner_button_url.href : ''}
            heroBannerButtonActionType={hero_banner_button_action_type}
            heroBannerButtonCallMeBackObject={call_me_back ? JSON.stringify(call_me_back) : ''}
            heroBannerSecondaryButtonText={hero_banner_secondary_button_text}
            heroBannerSecondaryButtonUrl={hero_banner_secondary_button_url ? hero_banner_secondary_button_url.href : ''}
            heroBannerSecondaryButtonActionType={hero_banner_secondary_button_action_type}
            heroBannerSecondaryButtonCallMeBackObject={call_me_back_secondary ? JSON.stringify(call_me_back_secondary) : ''}
            quickLinksHeader={quick_links_header}
            quickLinksMobileHeader={quick_links_mobile_header}
            quickLinksItem1={quick_links_item_1}
            quickLinksItem2={quick_links_item_2}
            quickLinksItem3={quick_links_item_3}
            quickLinksUrl1={quick_links_url_1}
            quickLinksUrl2={quick_links_url_2}
            quickLinksUrl3={quick_links_url_3}
            openTab={open_tab}
        >
            {
                call_left_text && call_left_text !== '<br />' && <text slot="click-to-call-left" dangerouslySetInnerHTML={{__html: call_left_text }} />
            }
            {
                backgroundImage && <div slot="page-bg-image" style={{ backgroundImage: `url('${backgroundImage}')` }} />
            }
            {
                bg_img_url && bg_img_url.gatsbyImageData ? (
                    <picture slot="hero-image">
                    <source
                        type={bg_img_url?.gatsbyImageData.images.sources[0].type}
                        srcSet={bg_img_url?.gatsbyImageData.images.sources[0].srcSet}
                        sizes={bg_img_url?.gatsbyImageData.images.sources[0].sizes}
                        media="(min-width: 786px)"
                    />
                    <img
                        height={bg_img_url?.gatsbyImageData.height}
                        srcSet={bg_img_url?.gatsbyImageData.images.sources[0].srcSet}
                        decoding="async"
                        loading="eager"
                        />
            </picture>) : ''
            }
            {
                banner_header && <span slot="banner-header" dangerouslySetInnerHTML={{__html: banner_header}} />
            }
            {
                banner_content && <span slot="banner-content" dangerouslySetInnerHTML={{__html: banner_content}} />
            }
            <MainNavigation slot="main-navigation" mainNavigationContent={main_navigation[0]} location={location} />
        </OmSegmentLandingHeader>
    );
};

export const pageQuery = graphql`
fragment SegmentLandingHeader on Contentstack_segment_landing_header {
    displaytext
    call_text
    call_number
    call_left_text
    bg_img_url {
        gatsbyImageData(
            placeholder: BLURRED
            formats: [AUTO, WEBP]
            layout: CONSTRAINED
            height: 540
        )
    }
    main_navigation {
        ...MainNavigation
    }
    banner_header
  	banner_content
  	hero_banner_button_text
  	hero_banner_button_url {
    	...Link
  	}
  	hero_banner_button_action_type
  	hero_banner_secondary_button_text
  	hero_banner_secondary_button_url {
    	...Link
  	}
  	hero_banner_secondary_button_action_type
  	quick_links_header
  	quick_links_mobile_header
  	quick_links_item_1
  	quick_links_url_1
  	quick_links_item_2
  	quick_links_url_2
  	quick_links_item_3
  	quick_links_url_3
    call_me_back {
        ...CallMeBack
    }
    call_me_back_secondary {
        ...CallMeBack
    }
    open_tab
}
`;

export default SegmentLandingHeader