import {graphql} from 'gatsby';
import React from 'react';
import TwoColumn from './TwoColumn';
import Tables from './Tables';
import ThreeColumn from './ThreeColumn';
//import ThreeColumnIllustration from './ThreeColumnIllustration';
import Carousel from './Carousel';
import FaqCards from './FaqCards';
import Banner from './Banner';
import ApplicationContainer from './ApplicationContainer';
import OneColumn from './OneColumn';
import OMIFrames from './iFrames';
import Filter from './Filter';
import OmCookiePolicySettingsWrapper from './CookiePolicySettings';
import OmCookiePopup from './CookiePopup';

const PageModularBlock = ({
    modularBlock,
    refinedArticleCards,
}) => {
    if (!modularBlock) {
        return '';
    }

    const isClient = typeof window !== 'undefined' ? window.localStorage : '';

    function displayCookiePopup(){
        if (isClient)
            return localStorage.getItem('displayCookiePopup') === 'false' ? false: true;
    }

    const {
        application_container_block,
        table_block,
        two_column_block,
        three_column_illustration_block,
        one_column_block,
        banner_block,
        carousel_block,
        iframe_block,
        faq_cards_block,
        filter_block,
        cookie_settings,
        cookie_popup,
    } = modularBlock;

    if (application_container_block != null) {
        return (
            <ApplicationContainer applicationContainerContent={application_container_block.application_container[0]}/>
        );
    }

    if (table_block != null) {
        return (
            <Tables tableContent={table_block.table[0]}/>
        );
    }

    if (three_column_illustration_block != null) {
        return (
            <ThreeColumn
                threeColumnContent={three_column_illustration_block.three_column_illustration[0]}/>
        );
    }

    if (banner_block != null) {
        return (<Banner bannerContent={banner_block.banner[0]}/>);
    }

    if (two_column_block != null) {
        return (
            <TwoColumn twoColumnContent={two_column_block.two_column[0]}/>
        );
    }

    if (carousel_block != null) {
        return (
            <Carousel carouselContent={carousel_block.carousel[0]}/>
        );
    }

    if (one_column_block != null) {
        return <OneColumn oneColumnContent={one_column_block.one_column[0]} />
    }

    if (iframe_block != null) {
        return <OMIFrames iFramesContent={iframe_block.iframe[0]} />
    }

    if (faq_cards_block != null) {
        return (
            <FaqCards faqCardsContent={faq_cards_block.faq_cards[0]}/>
        );
    }
    if (filter_block != null) {
        return (
            <Filter filterContent={filter_block.filter[0]} refinedArticleCards={refinedArticleCards}/>
        );
    }
    if (cookie_settings != null) {
        return (
            <OmCookiePolicySettingsWrapper omCookiePolicySettingsWrapperContent={cookie_settings.cookie_policy_settings_section[0]} />
        );
    }

    if (cookie_popup != null && displayCookiePopup()) {

        return (
            <OmCookiePopup omCookiePopupContent={cookie_popup.cookie_banner[0]} />
        )
    }

    return '';
};

export const pageQuery = graphql`
fragment ModularBlock on Contentstack_modular_page_modular_blocks {
    id
    application_container_block {
        application_container {
            ...ApplicationContainer
        }
    }
    two_column_block {
        two_column {
            __typename
            ...TwoColumnImageLeft,
            ...TwoColumnImageLeftV2,
            ...TwoColumnImageRight,
            ...TwoColumnImageRightV2,
            ...TabsTwoColumnLayoutImageRight,
            ...TabsTwoColumnLayoutImageRightV2,
            ...TabsTwoColumnLayoutImageLeft,
            ...TabsTwoColumnLayoutImageLeftV2
        }
    }

    table_block {
        table {
            __typename
            ...Table,
            ...TabsTable
        }
    }

    three_column_illustration_block {
        three_column_illustration {
            __typename
            ...TabsThreeColumnIllustrations
            ...ThreeColumnIllustration
        }
    }

    one_column_block {
        one_column {
            __typename
            ...TabsOneColumnTextParagraph,
            ...PageIntro,
            ...Paragraph,
            ...TriggerQuestion,
            ...EventCountdownTimer,
            ...Poll
            ...ActionButton
        }
    }

    banner_block {
        banner {
            __typename
            ...RefinedInPageBanner,
            ...RefinedInPageBannerV2,
            ...RefinedPromoBanner,
            ...RefinedPromoBannerV2
        }
    }

    carousel_block {
        carousel {
            __typename
            ...ProductCarousel,
            ...DownloadCarousel,
            ...ArticleCarousel,
            ...TabsProductCarousel,
            ...InPageCarouselWrapper
            ...PillarCarousel
        }
    }

    iframe_block {
        iframe {
            ...IFrame,
            ...TabsIFrame
        }
    }

    faq_cards_block {
        faq_cards {
            ...FaqCards
        }
    }

    filter_block {
        filter {
            __typename
            ...ArticleCardFilter,
            ...ProductCardFilter
            ...PeopleCardFilter
        }
    }

    cookie_settings {
        cookie_policy_settings_section {
            ...CookiePolicySettings
        }
    }

    cookie_popup {
        cookie_banner {
            ...CookiePopup
        }
    }
}
`;

export default PageModularBlock;
