import React from 'react';
import HeaderWithBreadcrumbs from './HeaderWithBreadcrumbs';
import HeaderWithBreadcrumbsV2 from './HeaderWithBreadcrumbsV2';
import SegmentLandingHeader from './SegmentLandingHeader';
import SegmentLandingHeaderV2 from './SegmentLandingHeaderV2';
import HomePageHeader from './HomePageHeader';
import ArticleLandingHeader from './ArticleLandingHeader';

const HeaderContent = ({
    headerContent,
    pageBackgroundUrl,
    location,
}) => {
    if (!headerContent) {
        return '';
    }
    
    if (headerContent.__typename === 'Contentstack_article_landing_header') {
        return (
            <ArticleLandingHeader
                articleLandingHeaderContent={headerContent}
                background={pageBackgroundUrl ? pageBackgroundUrl.url : ''}
                location={location} />
        );
    }

    if (headerContent.__typename === 'Contentstack_segment_landing_header') {
        return (
            <SegmentLandingHeader
                segmentLandingHeaderContent={headerContent}
                backgroundImage={ pageBackgroundUrl ? pageBackgroundUrl.url : ''}
                location={location}
            />
        );
    }

    if (headerContent.__typename === 'Contentstack_segment_landing_header_v2') {
        return (
            <SegmentLandingHeaderV2
                segmentLandingHeaderContent={headerContent}
                backgroundImage={ pageBackgroundUrl ? pageBackgroundUrl.url : ''}
                location={location}
            />
        );
    }

    if (headerContent.__typename === 'Contentstack_header_with_breadcrumbs') {
        return (<HeaderWithBreadcrumbs
            headerWithBreadcrumbsContent={headerContent}
            backgroundImage={pageBackgroundUrl ? pageBackgroundUrl.url : ''}
            location={location}
        />)
    }

    if (headerContent.__typename === 'Contentstack_header_with_breadcrumbs_v2') {
        return (<HeaderWithBreadcrumbsV2
            headerWithBreadcrumbsContent={headerContent}
            backgroundImage={pageBackgroundUrl ? pageBackgroundUrl.url : ''}
            location={location}
        />)
    }

    if (headerContent.__typename === 'Contentstack_home_page_header') {
        return (<HomePageHeader
            homePageHeaderContent={headerContent}
            backgroundImage={pageBackgroundUrl ? pageBackgroundUrl.url : ''}
            location={location}
        />)
    }

    return '';
}

export default HeaderContent;
