import React from 'react';
import { graphql } from 'gatsby';
import { OmParalaxContainer } from '@om/component-library-react';

const ParallaxContainer = ({
    parallaxContainerContent
}) => {

    if (!parallaxContainerContent) {
        return '';
    };

    const {
        uid,
        image_one_url,
        image_two_url,
        image_three_url,
        image_four_url,
        image_five_url,
    } = parallaxContainerContent;

    return (
        <section>
            <OmParalaxContainer
                id={uid}
            >
                {
                    image_one_url ? <div slot="image-1" style={{ backgroundImage: `url(${image_one_url.url})` }} />  : ''
                }
                {
                    image_two_url ? <div slot="image-2" style={{ backgroundImage: `url(${image_two_url.url})` }} />  : ''
                }
                {
                    image_three_url ? <div slot="image-3" style={{ backgroundImage: `url(${image_three_url.url})` }} />  : ''
                }
                {
                    image_four_url ? <div slot="image-4" style={{ backgroundImage: `url(${image_four_url.url})` }} />  : ''
                }
                {
                    image_five_url ? <div slot="image-5" style={{ backgroundImage: `url(${image_five_url.url})` }} />  : ''
                }
            </OmParalaxContainer>
        </section>
    )
        
}
export const pageQuery = graphql`
fragment ParalaxContainer on Contentstack_paralax_container {
    uid
    image_one_url {
        ...File
    }
    image_two_url {
        ...File
    }
    image_three_url {
        ...File
    }
    image_four_url {
        ...File
    }
    image_five_url {
        ...File
    }
}
`;

export default ParallaxContainer;