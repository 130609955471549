import React from 'react';
import { graphql } from 'gatsby';
import { OmHeroBannerWrapper } from '@om/component-library-react';
import { GatsbyImage } from 'gatsby-plugin-image';




const HeroBannerWrapper = ({
    heroBannerWrapperContent,
    slot,
    backgroundImage,
}) => {
    if (!heroBannerWrapperContent) {
        return '';
    }

    const {
        hero_banner_button_text,
        hero_banner_button_url,
        hero_banner_button_action_type,
        call_me_back,
        call_me_back_secondary,
        hero_banner_secondary_button_text,
        hero_banner_secondary_button_url,
        hero_banner_secondary_button_action_type,
        open_tab,
        hero_banner_header,
        hero_banner_caption,
        bg_img_url,
    } = heroBannerWrapperContent;

    return (
        <OmHeroBannerWrapper
            slot={slot}
            heroBannerButtonText={hero_banner_button_text}
            heroBannerButtonUrl={hero_banner_button_url.href}
            heroBannerButtonAction-type={hero_banner_button_action_type}
            heroBannerButtonCallMeBackObject={JSON.stringify(call_me_back)}
            heroBannerSecondaryButtonText={hero_banner_secondary_button_text}
            heroBannerSecondaryButtonUrl={hero_banner_secondary_button_url.href}
            heroBannerSecondaryButtonActionType={hero_banner_secondary_button_action_type}
            heroBannerSecondaryButtonCallMeBackObject={JSON.stringify(call_me_back_secondary)}
            openTab={open_tab}
        >
            { backgroundImage && backgroundImage.trim() !== '' && <div slot="page-bg-image" style={{ backgroundImage: `url('${backgroundImage}')` }} /> }
            { bg_img_url && bg_img_url.gatsbyImageData && <GatsbyImage loading='eager' alt="hero banner background image" style={{height:'540px', position:'absolute', width:'100vw', zIndex:'-1'}} slot="hero-image" image={bg_img_url.gatsbyImageData} /> }
            { hero_banner_header && <span slot="hero-banner-header" dangerouslySetInnerHTML={{ __html: hero_banner_header }} /> }
            { hero_banner_caption && <span slot="hero-banner-caption" dangerouslySetInnerHTML={{ __html: hero_banner_caption }} /> }
        </OmHeroBannerWrapper>
    );
};


export const pageQuery = graphql`
fragment HeroBannerWrapper on Contentstack_hero_banner_wrapper {
    uid
    bg_img_url {
        gatsbyImageData(
            formats: [AUTO, WEBP]
            layout: CONSTRAINED
            placeholder: BLURRED
            quality: 100
          )
        uid
        title
        url
    }
    hero_banner_header
    hero_banner_caption
    hero_banner_button_text
    hero_banner_button_url {
        ...Link
    }
    hero_banner_button_action_type
        call_me_back {
        ...CallMeBack
    }

    hero_banner_secondary_button_text
    hero_banner_secondary_button_url {
        ...Link
    }
    hero_banner_secondary_button_action_type
        call_me_back_secondary {
        ...CallMeBack
    }
}
`;

export default HeroBannerWrapper;
