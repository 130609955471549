import React, {useEffect}  from 'react';
import { graphql } from 'gatsby';
import MainNavigation from './MainNavigation'
import Breadcrumbs from '../Breadcrumbs'
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getIsFormOpenAndPrepopulated} from '@om/redux/selectors/publicWeb/callMeBack';
import { handleOmButtonClick } from '../forms/OmButton';
import {
    toggleComplaintForm,
    toggleArbitrationForm
} from '@om/redux/actions/customerComplaint/complaintArbitration';
import { OmHeaderWithBreadcrumbs } from '@om/component-library-react';
import HeroBannerStickyButtons from './HeroBannerStickyButtons'
import './HeaderWithBreadcrumbs.scss';

const HeaderWithBreadcrumbs = ({
    headerWithBreadcrumbsContent,
    slot,
    backgroundImage,
    location
}) => {
    const dispatch = useDispatch();

    let cmbData;

    if(headerWithBreadcrumbsContent){
        cmbData = headerWithBreadcrumbsContent?.call_me_back[0] ? headerWithBreadcrumbsContent?.call_me_back
            : headerWithBreadcrumbsContent?.call_me_back_secondary;
    }

    const {
        isPrepopulatedCmb
    } = useSelector(
        createStructuredSelector({
            isPrepopulatedCmb: getIsFormOpenAndPrepopulated,

        })
    );

    useEffect(() => {
        if(isPrepopulatedCmb && cmbData)
            handleOmButtonClick(cmbData, dispatch, isPrepopulatedCmb, 'HeaderWithBreadcrumbs');
    }, [
        isPrepopulatedCmb,
        cmbData
    ]);

    if(!headerWithBreadcrumbsContent) {
        return '';
    }

    const {
        main_navigation,
        displaytext,
        call_text,
        call_number,
        call_left_text,
        bg_img_url,
        hero_banner_header,
        hero_banner_caption,
        hero_banner_button_action_type,
        hero_banner_button_text,
        hero_banner_button_url,
        hero_banner_secondary_button_action_type,
        hero_banner_secondary_button_text,
        hero_banner_secondary_button_url,
        call_me_back,
        call_me_back_secondary,
        open_tab,
        roa_quote_tool,
	    roa_quote_tool_secondary,
        hero_banner_sticky_buttons
    } = headerWithBreadcrumbsContent;
 
    if (call_me_back[0]){
        const product = call_me_back[0]?.call_me_back_product_v2[0] ?  
            call_me_back[0].call_me_back_product_v2[0].call_me_back_product : 
            call_me_back[0]?.call_me_back_product;
    
        if (product != null || product != undefined){
            call_me_back[0].call_me_back_product = product;
        }
    }

    if (call_me_back_secondary[0]){
        const product = call_me_back_secondary[0]?.call_me_back_product_v2[0] ?  
            call_me_back_secondary[0].call_me_back_product_v2[0].call_me_back_product : 
            call_me_back_secondary[0]?.call_me_back_product;
    
        if (product != null || product != undefined){
            call_me_back_secondary[0].call_me_back_product = product;
        }
    }

    return (
        <OmHeaderWithBreadcrumbs
            slot={slot}
            onOnButtonClicked={(e) => {
                if(e.detail === 'Complaint') {
                    dispatch(toggleComplaintForm());
                } else if (e.detail === 'Arbitration') {
                    dispatch(toggleArbitrationForm());
                }
            }}
            displaytext={displaytext ? displaytext : ''}
            callText={call_text ? call_text : ''}
            callNumber={call_number ? call_number : ''}
            heroBannerButtonText={hero_banner_button_text ? hero_banner_button_text : ''}
            heroBannerButtonUrl={hero_banner_button_url ? hero_banner_button_url.href : ''}
            heroBannerButtonActionType={hero_banner_button_action_type}
            heroBannerButtonCallMeBackObject={call_me_back ? JSON.stringify(call_me_back) : ''}
            heroBannerButtonRoaQuoteToolObject={roa_quote_tool ? JSON.stringify(roa_quote_tool[0]) : ''}
            heroBannerSecondaryButtonText={hero_banner_secondary_button_text ? hero_banner_secondary_button_text : ''}
            heroBannerSecondaryButtonUrl={hero_banner_secondary_button_url ? hero_banner_secondary_button_url.href : ''}
            heroBannerSecondaryButtonActionType={hero_banner_secondary_button_action_type}
            heroBannerSecondaryButtonCallMeBackObject={call_me_back_secondary ? JSON.stringify(call_me_back_secondary) : ''}
            heroBannerSecondaryButtonRoaQuoteToolObject={roa_quote_tool_secondary ? JSON.stringify(roa_quote_tool_secondary[0]) : ''}
            openTab={open_tab}
        >
            {
                call_left_text && call_left_text !== '<br />' && <text slot="click-to-call-left" dangerouslySetInnerHTML={{__html: call_left_text }} />
            }
            {
                backgroundImage ? <div slot="page-bg-image" style={{ backgroundImage: `url('${backgroundImage}')` }}/> : ''
            }
            {
                bg_img_url && bg_img_url.gatsbyImageData ? (
                    <picture slot="hero-image">
                    <source
                        type={bg_img_url?.gatsbyImageData.images.sources[0].type}
                        srcSet={bg_img_url?.gatsbyImageData.images.sources[0].srcSet}
                        sizes={bg_img_url?.gatsbyImageData.images.sources[0].sizes}
                        media="(min-width: 786px)"
                    />
                    <img
                        height={bg_img_url?.gatsbyImageData.height}
                        srcSet={bg_img_url?.gatsbyImageData.images.sources[0].srcSet}
                        style={{width:"100%", height:"540px", objectFit:"cover"}}
                        decoding="async"
                        loading="eager"
                        />
            </picture>) : ''
            }
            {
                hero_banner_header ? <span slot="hero-banner-header" dangerouslySetInnerHTML={{__html: hero_banner_header}} /> : ''
            }
            {
                hero_banner_caption ? <span slot="hero-banner-caption" dangerouslySetInnerHTML={{__html: hero_banner_caption}}/> : ''
            }
            {main_navigation && main_navigation.length > 0 &&
                <MainNavigation slot="main-navigation" mainNavigationContent={main_navigation[0]} location={location}/>
            }
            <Breadcrumbs slot="breadcrumbs" location={location}/>
            {   hero_banner_sticky_buttons && 
                <HeroBannerStickyButtons />
            }
        </OmHeaderWithBreadcrumbs>
    );
};

export const pageQuery = graphql`
fragment HeaderWithBreadcrumbs on Contentstack_header_with_breadcrumbs {
    main_navigation {
        ...MainNavigation
    }
    displaytext
    call_text
    call_number
    call_left_text
    bg_img_url {
        gatsbyImageData(
            formats: [AUTO, WEBP]
            layout: CONSTRAINED
            height: 540
            quality: 100
        )
        url
    }
    hero_banner_header
    hero_banner_caption
    hero_banner_button_action_type
    hero_banner_button_text
    hero_banner_button_url {
        ...Link
    }
    hero_banner_secondary_button_action_type
    hero_banner_secondary_button_text
    hero_banner_secondary_button_url {
        ...Link
    }
    call_me_back {
        ...CallMeBack
    }
    roa_quote_tool {
        ...RoaQuoteTool
    }
    call_me_back_secondary {
        ...CallMeBack
    }
    roa_quote_tool_secondary {
        ...RoaQuoteTool
    }
    open_tab
    hero_banner_sticky_buttons
}
`;

export default HeaderWithBreadcrumbs
