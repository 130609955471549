import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby';

import PeopleCard from './PeopleCard';
import {
    OmPeopleCardFilter,
    OmSectionHeader,
    OmCheckBox,
} from '@om/component-library-react';

const PeopleCardFilter = ({
    peopleCardFilterContent,
}) => {
    const [primaryFilterIndex, setPrimaryFilter] = useState(0);
    const [secondaryFilterList, setSecondaryFilterList] = useState([]);
    const [secondaryFilterIndex, setSecondaryFilterIndex] = useState(0);
    const [filteredData, setFilteredData] = useState({});

    useEffect(() => {
        if (peopleCardFilterContent) {
            if (card_filter_items && card_filter_items.length > 0) {
                let allPeopleCards = [];
                card_filter_items.map((data) => {
                    return allPeopleCards.push(...data.people_cards);
                });
                if (has_tabs) {
                    setTabsName(allPeopleCards);
                }
                setupTabsData(card_filter_items[primaryFilterIndex].people_cards);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!peopleCardFilterContent) {
        return '';
    }
    const {
        uid,
        heading,
        top_line,
        sub_title,
        caption,
        reset_button_text,
        has_boundary,
        has_tabs,
        first_filter_heading,
        second_filter_heading,
        card_filter_items,
    } = peopleCardFilterContent;

    function updatePrimaryFilterIndex(primaryFilterIndex) {
        setPrimaryFilter(primaryFilterIndex);
        setupTabsData(card_filter_items[primaryFilterIndex].people_cards);
    }

    function updateSecondaryFilterIndex(secondaryFilterIndex) {
        setSecondaryFilterIndex(secondaryFilterIndex);
    }

    function getCardClass(index) {
        let cardclass = 'hydrated';
        if (index === 0 || index % 3 === 0) {
            cardclass += ' new-row-xl';
        }
        if (index === 0 || index % 2 === 0) {
            cardclass += ' new-row-lg';
        }
        return cardclass;
    }

    function setTabsName(allPeopleCards) {
        const categories = [
            ...new Set(allPeopleCards.map((people_card) => people_card.people_card_category)),
        ];
        setSecondaryFilterList(categories);
    }

    function setupTabsData(peopleCards) {
        const filteredData = peopleCards.reduce((people_card, a) => {
            people_card[a.people_card_category] = people_card[a.people_card_category] || [];
            people_card[a.people_card_category].push(a);
            return people_card;
        }, Object.create(null));

        setFilteredData(filteredData);
    }

    return (
        <section>
            <OmPeopleCardFilter
                id={uid}
                resetButtonText={reset_button_text}
                hasBoundary={has_boundary}
            >
                {top_line ? <span slot="top-line" dangerouslySetInnerHTML={{ __html: top_line }} /> : ''}
                {heading ? <span slot="heading" dangerouslySetInnerHTML={{ __html: heading }} /> : ''}
                {sub_title ? <span slot="sub_title" dangerouslySetInnerHTML={{ __html: sub_title }} /> : ''}
                {caption ? <span slot="caption" dangerouslySetInnerHTML={{ __html: caption }} /> : ''}
                <div slot="people-cards">
                    <div className="filter-items-container">
                        {first_filter_heading ? <div className="filter-heading" slot="filter-heading-one" dangerouslySetInnerHTML={{ __html: first_filter_heading }} /> : ''}
                        <div slot="filter-container">
                            {card_filter_items && card_filter_items.map((cardFilterItem, index) => {
                                return (
                                    <div className="filter-item" key={index}>
                                        <OmCheckBox
                                            className="filter-button"
                                            hasIcon={true}
                                            isButtonCheckbox={true}
                                            key={cardFilterItem.uid}
                                            text={cardFilterItem.check_box_text}
                                            checked={index === primaryFilterIndex ? true : false}
                                            onOnCheckBoxChanged={() => updatePrimaryFilterIndex(index)}
                                        />
                                    </div>
                                );
                            })}
                        </div>
                        {second_filter_heading ? <div className="filter-heading" slot="filter-heading-two" dangerouslySetInnerHTML={{ __html: second_filter_heading }} /> : ''}
                        {secondaryFilterList && secondaryFilterList.map((secondaryFilter, index) => {
                            return (
                                <OmCheckBox
                                    className="filter-button"
                                    hasIcon={true}
                                    isButtonCheckbox={true}
                                    key={index}
                                    text={secondaryFilter}
                                    btnType="text"
                                    checked={index === secondaryFilterIndex ? true : false}
                                    onOnCheckBoxChanged={() => updateSecondaryFilterIndex(index)}
                                />
                            );
                        })}
                    </div>
                    <div className="people-card-filter">
                        {
                            has_tabs ?
                                <div>
                                    {filteredData[secondaryFilterList[secondaryFilterIndex]]
                                        ? filteredData[secondaryFilterList[secondaryFilterIndex]]
                                            .map((peopleCard, index) => {
                                                return (
                                                    <PeopleCard
                                                        className={getCardClass(index)}
                                                        key={peopleCard.uid}
                                                        peopleCardContent={peopleCard}
                                                    />
                                                );
                                            }) :
                                        <OmSectionHeader headerType="content" textAlignCenter={true}>
                                            <h3 slot="heading" className="no-top-margin no-bottom-margin">
                                                No records found.
                                            </h3>
                                        </OmSectionHeader>
                                    }
                                </div>
                                :
                                <div>
                                    {card_filter_items[primaryFilterIndex].people_cards
                                        ? card_filter_items[primaryFilterIndex].people_cards
                                            .map((peopleCard, index) => {
                                                return (
                                                    <PeopleCard
                                                        className={getCardClass(index)}
                                                        key={peopleCard.uid}
                                                        peopleCardContent={peopleCard}
                                                    />
                                                );
                                            }) :
                                        <OmSectionHeader headerType="content" textAlignCenter={true}>
                                            <h3 slot="heading" className="no-top-margin no-bottom-margin">
                                                No records found.
                                            </h3>
                                        </OmSectionHeader>
                                    }
                                </div>
                        }

                    </div>
                </div>
            </OmPeopleCardFilter>
        </section>
    );
};

export const pageQuery = graphql`
fragment PeopleCardFilter on Contentstack_people_card_filter {
    uid
    title
    heading
    use_heading_as_h1
    top_line
    sub_title
    caption
    reset_button_text
    has_boundary
    has_tabs
    first_filter_heading
    second_filter_heading
    card_filter_items {
       ...PeopleCardFilterItem
    }
  }

  fragment PeopleCardFilterItem on Contentstack_people_card_filter_item {
    uid
    check_box_text
    people_cards {
       ...PeopleCard
    }
  }
`;

export default PeopleCardFilter;
