import React from 'react';
import { OmCookiePolicySettings } from '@om/component-library-react';
import { graphql } from 'gatsby';

const OmCookiePolicyWrapper = ({
    omCookiePolicySettingsWrapperContent
}) => {

    if(!omCookiePolicySettingsWrapperContent) {
        return '';
    }

    const {
        preferences_one_heading,
        preferences_one_text,
        preferences_one_icon,
        preferences_two_heading,
        preferences_two_text,
        preferences_two_icon,
        preferences_two_checkbox,
        preferences_three_heading,
        preferences_three_text,
        preferences_three_icon,
        preferences_three_checkbox,
        preferences_four_heading,
        preferences_four_text,
        preferences_four_icon,
        preferences_four_checkbox,
    } = omCookiePolicySettingsWrapperContent;
    
    return (
        <OmCookiePolicySettings 
            preferencesOneHeading={preferences_one_heading}
            preferencesOneText={preferences_one_text}
            preferencesOneIcon={preferences_one_icon.url}
            preferencesTwoHeading={preferences_two_heading}
            preferencesTwoText={preferences_two_text}
            preferencesTwoIcon={preferences_two_icon.url}
            preferencesTwoCheckbox={preferences_two_checkbox}
            preferencesThreeHeading={preferences_three_heading}
            preferencesThreeText={preferences_three_text}
            preferencesThreeIcon={preferences_three_icon.url}
            preferencesThreeCheckbox={preferences_three_checkbox}
            preferencesFourHeading={preferences_four_heading}
            preferencesFourText={preferences_four_text}
            preferencesFourIcon={preferences_four_icon.url}
            preferencesFourCheckbox={preferences_four_checkbox}
            isCookieBanner={false}
        />
    )

}

export default OmCookiePolicyWrapper;

export const pageQuery = graphql`
fragment CookiePolicySettings on Contentstack_cookie_policy_settings {
    preferences_one_heading
    preferences_one_text
    preferences_one_icon {
        ...File
    }
    preferences_two_heading
    preferences_two_text
    preferences_two_icon {
        ...File
    }
    preferences_two_checkbox

    preferences_three_heading
    preferences_three_text
    preferences_three_icon {
        ...File
    }
    preferences_three_checkbox

    preferences_four_heading
    preferences_four_text
    preferences_four_icon {
        ...File
    }
    preferences_four_checkbox
}
`;