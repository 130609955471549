import { createSelector } from "reselect";

export const getArticleCardFilter = state => state.publicWeb.articleCardFilter;

export const getSelectedPrimaryFilters = createSelector(
    getArticleCardFilter,
    articleCardFilter => articleCardFilter.selectedPrimaryFilters
);

export const getSelectedSecondaryFilters = createSelector(
    getArticleCardFilter,
    articleCardFilter => articleCardFilter.selectedSecondaryFilters
);

export const getPrimaryFilters = createSelector(
    getArticleCardFilter,
    articleCardFilter => articleCardFilter.primaryFilters
);

export const getSecondaryFilters = createSelector(
    getArticleCardFilter,
    articleCardFilter => articleCardFilter.secondaryFilters
);

export const getCardsPerPage = createSelector(
    getArticleCardFilter,
    articleCardFilter => articleCardFilter.cardsPerPage
);

export const getCurrentPage = createSelector(
    getArticleCardFilter,
    articleCardFilter => articleCardFilter.currentPage
);

const checkFilterSelection = (selectedFilters, cardFilters) => {
    const cardFilterIds = getFilterIdList(cardFilters);
    for (let selectedFilterKey in selectedFilters) {
        let selectedFilter = selectedFilters[selectedFilterKey];
        if (cardFilterIds.includes(selectedFilter)) {
            return true;
        }
    }

    return false;
};

const getArticleCardPublishTimestamp = articleCard => {
    let publishTimestamp;

    if (articleCard.publish_date && articleCard.publish_date != '') {
        try {
            publishTimestamp = new Date(articleCard.publish_date).getTime();
        } catch (e) {
            // Do nothing
        }
    }

    if (!publishTimestamp) {
        publishTimestamp = articleCard.created_at_timestamp;
    }

    return publishTimestamp;
};

export const getFilteredArticleCards = createSelector(
    getArticleCardFilter,
    articleCardFilter => {
        let filteredCards = [];
        let selectedPrimaryFilters = [...articleCardFilter.selectedPrimaryFilters];
        let selectedSecondaryFilters = [...articleCardFilter.selectedSecondaryFilters];

        // When all is selected (as in when there are no primary keys, fill the selectedFilters object with primary keys)
        const primaryAllButtonSelected = getAllSelected(articleCardFilter.primaryFilters, articleCardFilter.selectedPrimaryFilters);
        const secondaryAllButtonSelected = getAllSelected(articleCardFilter.secondaryFilters, articleCardFilter.selectedSecondaryFilters);

        if (primaryAllButtonSelected) {
            selectedPrimaryFilters = [
                ...selectedPrimaryFilters,
                ...getFilterIdList(articleCardFilter.primaryFilters)
            ];
        }

        if (secondaryAllButtonSelected) {
            selectedSecondaryFilters = [
                ...selectedSecondaryFilters,
                ...getFilterIdList(articleCardFilter.secondaryFilters)
            ];
        }

        const articleCards = articleCardFilter.cards;
        for (let cardIndex in articleCards) {
            let articleCard = articleCards[cardIndex];
            let addCard = true;
            if (articleCardFilter.primaryFilters.length > 0 && !checkFilterSelection(selectedPrimaryFilters, articleCard.card_filter_item)) {
                addCard = false;
            }
            if (addCard && articleCardFilter.secondaryFilters.length > 0 && !checkFilterSelection(selectedSecondaryFilters, articleCard.secondary_filter_items)) {
                addCard = false;
            }

            if (addCard) {
                filteredCards.push(articleCard);
            }
        }

        filteredCards.sort((a, b) => {
            return getArticleCardPublishTimestamp(b) - getArticleCardPublishTimestamp(a);
        });

        const startingIndex = (articleCardFilter.currentPage - 1) * articleCardFilter.cardsPerPage;
        const pagedFilteredCards = filteredCards.slice(startingIndex, startingIndex + articleCardFilter.cardsPerPage);

        return {
            filteredCards: pagedFilteredCards,
            cardCount: filteredCards.length - 1,
        };
    },
);

const getAllSelected = (filters, selectedFilters) => {
    const filterIdList = getFilterIdList(filters);
    let allSelected = true;
    for (const filterIdKey in filterIdList) {
        if (selectedFilters.includes(filterIdList[filterIdKey])) {
            allSelected  = false;
        }
    }
    return allSelected;
}

const getFilterIdList = filters => {
    const filterIds = [];
    for (let filterKey in filters) {
        const filterId = filters[filterKey].uid;
        if (!filterIds.includes(filterId)) {
            filterIds.push(filterId);
        }
    }
    return filterIds;
}

export const isPrimaryAllButtonSelected = createSelector(
    getArticleCardFilter,
    articleCardFilter => {
        return getAllSelected(articleCardFilter.primaryFilters, articleCardFilter.selectedPrimaryFilters);
    }
);

export const isSecondaryAllButtonSelected = createSelector(
    getArticleCardFilter,
    articleCardFilter => {
        return getAllSelected(articleCardFilter.secondaryFilters, articleCardFilter.selectedSecondaryFilters);
    }
);

export const isFiltered = createSelector(
    getArticleCardFilter,
    articleCardFilter => {
        return  articleCardFilter.selectedPrimaryFilters.length > 0 || articleCardFilter.selectedSecondaryFilters.length > 0;
    }
);
