import React, {useEffect} from 'react';
import { graphql } from 'gatsby';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import MainNavigation from './MainNavigation'
import { handleOmButtonClick } from '../forms/OmButton';
import { OmSegmentLandingHeader } from '@om/component-library-react';
import { getIsFormOpenAndPrepopulated} from '@om/redux/selectors/publicWeb/callMeBack';
import './SegmentLandingHeader.scss';

const SegmentLandingHeaderV2 = ({
    segmentLandingHeaderContent,
    slot,
    backgroundImage,
    location
}) => {
    const dispatch = useDispatch();
    let cmbData;

    if(segmentLandingHeaderContent){

        let isPrimaryBtnCmb = segmentLandingHeaderContent?.hero_banner_primary_button?.display_text
        === 'CALL ME BACK';
        cmbData = isPrimaryBtnCmb ? segmentLandingHeaderContent?.hero_banner_primary_button 
            : segmentLandingHeaderContent?.hero_banner_secondary_button;
    }

    const {
        isPrepopulatedCmb
    } = useSelector(
        createStructuredSelector({
            isPrepopulatedCmb: getIsFormOpenAndPrepopulated,

        })
    );

    useEffect(() => {
        if(isPrepopulatedCmb && cmbData)
            handleOmButtonClick(cmbData, dispatch, isPrepopulatedCmb, 'SegmentLandingHeaderV2');
    }, [
        isPrepopulatedCmb,
        cmbData
    ]);

    if(!segmentLandingHeaderContent) {
        return '';
    }

    const {
        display_text,
        call_text,
        call_number,
        call_left_text,
        bg_img_url,
        main_navigation,
        hero_banner_header,
        hero_banner_caption,
        hero_banner_primary_button,
        hero_banner_secondary_button,
        quick_links_header,
        quick_links_header_mobile,
        quick_links_item_1,
        quick_links_url_1,
        quick_links_item_2,
        quick_links_url_2,
        quick_links_item_3,
        quick_links_url_3,
    } = segmentLandingHeaderContent;

    return (
        <OmSegmentLandingHeader
            slot={slot}
            onButtonAction={(e) => {
                handleOmButtonClick(e, dispatch, isPrepopulatedCmb);
            }}
            displaytext={display_text}
            callText={call_text}
            callNumber={call_number}
            heroBannerButtonText={hero_banner_primary_button && hero_banner_primary_button.display_text}
            heroBannerButtonActionObject={hero_banner_primary_button && hero_banner_primary_button.action && JSON.stringify(hero_banner_primary_button.action[0])}
            heroBannerSecondaryButtonText={hero_banner_secondary_button && hero_banner_secondary_button.display_text}
            heroBannerSecondaryButtonActionObject={hero_banner_secondary_button && hero_banner_secondary_button.action && JSON.stringify(hero_banner_secondary_button.action[0])}
            quickLinksHeader={quick_links_header}
            quickLinksMobileHeader={quick_links_header_mobile}
            quickLinksItem1={quick_links_item_1}
            quickLinksItem2={quick_links_item_2}
            quickLinksItem3={quick_links_item_3}
            quickLinksUrl1={quick_links_url_1}
            quickLinksUrl2={quick_links_url_2}
            quickLinksUrl3={quick_links_url_3}
        >
            {
                call_left_text && call_left_text !== '<br />' && <text slot="click-to-call-left" dangerouslySetInnerHTML={{__html: call_left_text }} />
            }
            {
                backgroundImage && <div slot="page-bg-image" style={{ backgroundImage: `url('${backgroundImage}')` }} />
            }
            {
                bg_img_url && bg_img_url.gatsbyImageData ? (
                    <picture slot="hero-image">
                    <source
                        type={bg_img_url?.gatsbyImageData.images.sources[0].type}
                        srcSet={bg_img_url?.gatsbyImageData.images.sources[0].srcSet}
                        sizes={bg_img_url?.gatsbyImageData.images.sources[0].sizes}
                        media="(min-width: 786px)"
                    />
                    <img
                        height={bg_img_url?.gatsbyImageData.height}
                        src={bg_img_url?.gatsbyImageData.images.fallback?.src}
                        decoding="async"
                        loading="eager"
                        alt="hero banner with breadcrumbs image"
                        />
            </picture>) : ''
            }
            {
                hero_banner_header && <span slot="banner-header" dangerouslySetInnerHTML={{__html: hero_banner_header}}/>
            }
            {
                hero_banner_caption && <span slot="banner-content" dangerouslySetInnerHTML={{__html: hero_banner_caption}} />
            }
            <MainNavigation slot="main-navigation" mainNavigationContent={main_navigation[0]} location={location}/>
        </OmSegmentLandingHeader>
    );
};

export const pageQuery = graphql`
fragment SegmentLandingHeaderV2 on Contentstack_segment_landing_header_v2 {
    display_text
    call_text
    call_number
    call_left_text
    bg_img_url {
        gatsbyImageData(
            placeholder: BLURRED
            formats: [AUTO, WEBP]
            layout: CONSTRAINED
            height: 540
        )
    }
    main_navigation {
        ...MainNavigation
    }
    hero_banner_header
  	hero_banner_caption
    hero_banner_primary_button {
        ...SegmentLandingHeaderPrimaryButton
    }
    hero_banner_secondary_button {
        ...SegmentLandingHeaderSecondaryButton
    }
  	quick_links_header
  	quick_links_header_mobile
  	quick_links_item_1
  	quick_links_url_1
  	quick_links_item_2
  	quick_links_url_2
  	quick_links_item_3
  	quick_links_url_3
}
`;

export default SegmentLandingHeaderV2;
