import React from 'react';
import { graphql } from 'gatsby';
import { OmTimedHeaderCarousel } from '@om/component-library-react';
import HeroBannerWrapper from './HeroBannerWrapper';

const TimedHeaderCarousel = ({
    timedHeaderCarouselContent,
    slot,
    backgroundImage,
}) => {
    if (!timedHeaderCarouselContent) {
        return '';
    }

    const {
        uid,
        time_delay,
        hero_banners_slot,
    } = timedHeaderCarouselContent;

    return (
        <OmTimedHeaderCarousel
            slot={slot}
            timeDelay={time_delay}
        >
            <ul slot="hero-banners-slot">
                {hero_banners_slot.map(i => <HeroBannerWrapper key={i.uid} heroBannerWrapperContent={i} backgroundImage={backgroundImage} />)}
            </ul>
        </OmTimedHeaderCarousel>
    );
};

export const pageQuery = graphql`
fragment TimedHeaderCarousel on Contentstack_timed_carousel_banner {
    uid
    time_delay
    hero_banners_slot {
        ...HeroBannerWrapper
    }
}
`;

export default TimedHeaderCarousel;
