import React from 'react';
import { OmPagination } from '@om/component-library-react';

const Pagination = ({
    paginationRightClick,
    paginationLeftClick,
    paginationItemClick,
    cardCount,
    currentPage,
    resultsPerPage
    
}) => {

    return (
        <OmPagination
            resultsPerPage={resultsPerPage}
            totalResults={cardCount}
            activePageNumber={currentPage}
            onPaginationRightClick={(event) => paginationRightClick && paginationRightClick(event)}
            onPaginationLeftClick={(event) => paginationLeftClick && paginationLeftClick(event)}
            onPaginationItemClick={(event) => paginationItemClick && paginationItemClick(event)}
        />
    );
};
export default Pagination