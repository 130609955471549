import React from 'react';
import { graphql } from 'gatsby';
import { OmHomePageHeader } from '@om/component-library-react';
import MainNavigation from './MainNavigation';
import TimedHeaderCarousel from './TimedHeaderCarousel';

const HomePageHeader = ({
    backgroundImage,
    homePageHeaderContent,
    location,
    slot,
}) => {
    if (!homePageHeaderContent) {
        return '';
    }

    const {
        main_navigation,
        timed_header_slot,
        displaytext,
        call_text,
        call_number,
        call_left_text,
    } = homePageHeaderContent;

    return (
        <OmHomePageHeader
            slot={slot}
            displaytext={displaytext}
            callText={call_text}
            callNumber={call_number}
            background={backgroundImage}
        >
            {
                call_left_text && call_left_text !== '<br />' && <text slot="click-to-call-left" dangerouslySetInnerHTML={{__html: call_left_text }} />
            }
            <MainNavigation slot="main-navigation" mainNavigationContent={main_navigation[0]} location={location} />
            <TimedHeaderCarousel slot="timed-header-slot" timedHeaderCarouselContent={timed_header_slot[0]} background={backgroundImage} />
        </OmHomePageHeader>
    );
};

export const pageQuery = graphql`
fragment HomePageHeader on Contentstack_home_page_header {
    uid
    displaytext
    call_text
    call_number
    call_left_text
    main_navigation {
        ...MainNavigation
    }
    timed_header_slot {
        ...TimedHeaderCarousel
    }
}
`;

export default HomePageHeader;
