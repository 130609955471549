import React from 'react';
import ThreeColumnIllustration from './ThreeColumnIllustration';
import TabsThreeColumnIllustrations from './TabsThreeColumnIllustrations';

const ThreeColumn = ({ threeColumnContent }) => {
    if (!threeColumnContent)
        return '';  
       
    // if (threeColumnContent.__typename === 'Contentstack_tabs_three_column_illustrations')
    //     return (<TabsThreeColumnIllustrations  tabsThreeColumnIllustrationContent={threeColumnContent} />);
    
    if (threeColumnContent.__typename === 'Contentstack_3_col_illustration')
        return (<ThreeColumnIllustration threeColumnIllustrationContent={threeColumnContent} />);

    return '';
}

export default ThreeColumn;