import './HeroBannerStickyButtons.scss';

const HeroBannerStickyButtons = ({pageID}) => {
    let intervalID = null;

    function mountObserver () {

        if (typeof window !== 'undefined') {

            let target = document.querySelector(".hero-banner-container");

            if (target) {
                clearInterval(intervalID);

                let options = {
                    rootMargin: "0px",
                    threshold: 0.1,
                };
            
                let callback = (entries, observer) => {
                    entries.forEach((entry) => {
                        let buttonContainer = entry.target.querySelector('.om-hero-banner-content-container');
                        let gatsbyContainer = document.getElementById('___gatsby')

                        if (entry.isIntersecting) {
                            buttonContainer.classList.remove('fixed-hero-container');
                            gatsbyContainer.classList.remove('fixed-buttons');
                        } else {
                            buttonContainer.classList.add('fixed-hero-container');
                            gatsbyContainer.classList.add('fixed-buttons');
                        }
                    }); 
                };
            
                let observer = new IntersectionObserver(callback, options);
                    
                observer.observe(target);
            }
        }
    }

    intervalID = setInterval(mountObserver, 100);

    return '';
}

export default HeroBannerStickyButtons;