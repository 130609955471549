import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import { useSelector, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
    getPrimaryFilters,
    getSelectedPrimaryFilters,
    isPrimaryAllButtonSelected,
    isSecondaryAllButtonSelected,
    getSecondaryFilters,
    getSelectedSecondaryFilters,
    getFilteredArticleCards,
    getCardsPerPage,
    getCurrentPage,
    isFiltered
} from '@om/redux/selectors/publicWeb/articleCardFilter';
import {
    toggleAllPrimaryFilters,
    toggleSelectedPrimaryFilterItem,
    setCardFilter,
    toggleSelectedSecondaryFilterItem,
    setCards,
    setCurrentPage,
    toggleAllSecondaryFilters,
    filterByPrimaryQueryParam,
    filterBySecondaryQueryParam
} from '@om/redux/actions/publicWeb/articleCardFilter';

import {
    OmArticleCardFilter,
    OmCheckBox,
} from '@om/component-library-react';

import RefinedArticleCard from './RefinedArticleCard';
import Pagination from './Pagination';

function display_filter(show_filter) {
    if (show_filter != null) {
        return show_filter;
    }
    return true;
}

function getFilterClass(show_filter) {
    let filterClass = 'filter-items-container'
    if (display_filter(show_filter) !== true) {
        filterClass += ' hidden';
    }
    return filterClass;
}

function getCardClass(index) {
    let cardclass = 'hydrated';
    if (index === 0 || index % 3 === 0) {
        cardclass += ' new-row-xl';
    }
    if (index === 0 || index % 2 === 0) {
        cardclass += ' new-row-l';
    }
    return cardclass;
}

function scrollToFilterDelayed () {
    setTimeout(() => {
        scrollToFilter();
    }, 500);
}

function getQueryParam(key) {
    const queryString = require('query-string');
    const urlParams = queryString.parse(window.location.search);
    if (urlParams && urlParams[key]) {
        return urlParams[key];
    }
}

function getPrimaryPrefilterQueryId() {
    return getQueryParam('key');
}

function getSecondaryPrefilterQueryId() {
    return getQueryParam('key2');
}

function scrollToFilter () {
    const articleFitlerElem = document.getElementById('article-cards');
    if (articleFitlerElem) {
        const scrolly = articleFitlerElem.getBoundingClientRect().y + window.scrollY;
        window.scrollTo({ top: scrolly - 150});
    }
}

const ArticleCardFilter = ({
    articleCardFilterContent,
    refinedArticleCards,
}) => {

    const dispatch = useDispatch();

    const {
        primaryFilters,
        selectedPrimaryFilters,
        primaryAllButtonSelected,
        secondaryAllButtonSelected,
        secondaryFilters,
        selectedSecondaryFilters,
        filteredArticleCards,
        cardsPerPage,
        currentPage,
        filtered
    } = useSelector(
        createStructuredSelector({
            primaryFilters: getPrimaryFilters,
            selectedPrimaryFilters: getSelectedPrimaryFilters,
            primaryAllButtonSelected: isPrimaryAllButtonSelected,
            secondaryAllButtonSelected: isSecondaryAllButtonSelected,
            secondaryFilters: getSecondaryFilters,
            selectedSecondaryFilters: getSelectedSecondaryFilters,
            filteredArticleCards: getFilteredArticleCards,
            cardsPerPage: getCardsPerPage,
            currentPage: getCurrentPage,
            filtered: isFiltered
        })
    );

    useEffect(() => {
        if (articleCardFilterContent) {
            dispatch(setCardFilter(articleCardFilterContent));
            dispatch(setCards(refinedArticleCards, primaryFilters, secondaryFilters));
            dispatch(filterByPrimaryQueryParam(getPrimaryPrefilterQueryId()));
            dispatch(filterBySecondaryQueryParam(getSecondaryPrefilterQueryId()));
            if(filtered){
                scrollToFilterDelayed();
            }
        }
    // NOTE: do not obey the linter here
    // eslint-disable-next-line
    }, [
        dispatch,
        articleCardFilterContent,
        refinedArticleCards,
    ]);

    if (!articleCardFilterContent) {
        return '';
    }

    const {
        uid,
        heading,
        top_line,
        sub_title,
        caption,
        first_filter_heading,
        second_filter_heading,
        reset_button_text,
        has_boundary,
        is_extended_filter,
        show_filter,
    } = articleCardFilterContent

    function updateSetCurrentPage(event) {
        dispatch(setCurrentPage(event.detail));
        scrollToFilter();
    }

    function updateToggleAllPrimaryFilters(event) {
        dispatch(toggleAllPrimaryFilters(event));
    }

    function updateToggleSelectedPrimaryFilterItem(uid, event) {
        dispatch(toggleSelectedPrimaryFilterItem(uid, event.detail));
    }

    function updateToggleAllSecondaryFilters(event) {
        dispatch(toggleAllSecondaryFilters(event));
    }

    function updateToggleSelectedSecondaryFilterItem(uid, event) {
        dispatch(toggleSelectedSecondaryFilterItem(uid, event.detail));
    }

    return (
        <section>
            <OmArticleCardFilter
                id={uid}
                resetButtonText={reset_button_text}
                hasBoundary={has_boundary}
            >
                { top_line ? <span slot="top-line" dangerouslySetInnerHTML={{ __html: top_line }} /> : ''}
                { heading ? <span slot="heading" dangerouslySetInnerHTML={{ __html: heading }} /> : ''}
                { sub_title ? <span slot="sub_title" dangerouslySetInnerHTML={{ __html: sub_title }} /> : ''}
                { caption ? <span slot="caption" dangerouslySetInnerHTML={{ __html: caption }} /> : ''}
                <div id="article-cards" slot="article-cards">
                    <div className="filter-items-container">
                        { first_filter_heading ? <div className="filter-heading" dangerouslySetInnerHTML={{ __html: first_filter_heading }} /> : ''}
                        <div className={getFilterClass(show_filter)}>
                            <OmCheckBox
                                className="filter-button"
                                isButtonCheckbox="true"
                                ignoreDisabledStyle="true"
                                text='ALL'
                                disabled={primaryAllButtonSelected}
                                checked={primaryAllButtonSelected}
                                onOnCheckBoxChanged={(event) => updateToggleAllPrimaryFilters(event)}
                            />
                            { primaryFilters && selectedPrimaryFilters && primaryFilters.map((primaryFilter) => {
                                return (
                                    <OmCheckBox
                                        className="filter-button"
                                        key={primaryFilter.uid}
                                        isButtonCheckbox={true}
                                        fieldId={primaryFilter.uid}
                                        text={primaryFilter.check_box_text}
                                        hasIcon={true}
                                        checked={selectedPrimaryFilters.includes(primaryFilter.uid)}
                                        onOnCheckBoxChanged={(event) => updateToggleSelectedPrimaryFilterItem(primaryFilter.uid, event)}
                                    />

                                );
                            })}
                        </div>
                        { second_filter_heading ? <div className="filter-heading" dangerouslySetInnerHTML={{ __html: second_filter_heading }} /> : ''}
                        { secondaryFilters && secondaryFilters.length > 0 &&
                            <div className={getFilterClass(show_filter)}>
                                <OmCheckBox
                                    className="filter-button"
                                    isButtonCheckbox={true}
                                    text="All"
                                    btnType="text"
                                    ignoreDisabledStyle={true}
                                    disabled={secondaryAllButtonSelected}
                                    checked={secondaryAllButtonSelected}
                                    onOnCheckBoxChanged={(event) => updateToggleAllSecondaryFilters(event)}
                                />
                                { secondaryFilters && secondaryFilters.map((secondaryFilter) => {
                                    return (
                                        <OmCheckBox
                                            className="filter-button"
                                            hasIcon={true}
                                            isButtonCheckbox={true}
                                            key={secondaryFilter.uid}
                                            text={secondaryFilter.check_box_text}
                                            btnType="text"
                                            checked={selectedSecondaryFilters.includes(secondaryFilter.uid)}
                                            onOnCheckBoxChanged={(event) => updateToggleSelectedSecondaryFilterItem(secondaryFilter.uid, event)}
                                        />
                                    );
                                })}
                            </div>
                        }
                    </div>
                    { filteredArticleCards ? filteredArticleCards.filteredCards.map((articleCard, index) => {
                        return (
                            <RefinedArticleCard
                                className={getCardClass(index)}
                                key={articleCard.uid}
                                refinedArticleCardContent={articleCard}
                                isExtended={is_extended_filter}
                            />
                        );
                    }) : '' }
                    { filteredArticleCards && filteredArticleCards.filteredCards.length === 0 &&
                        <h4 className="center">No Results Found</h4>
                    }
                    <Pagination
                        resultsPerPage={cardsPerPage}
                        cardCount={filteredArticleCards.cardCount}
                        currentPage={currentPage}
                        paginationLeftClick={event => updateSetCurrentPage(event)}
                        paginationRightClick={event => updateSetCurrentPage(event)}
                        paginationItemClick={event => updateSetCurrentPage(event)}
                    />
                </div>
            </OmArticleCardFilter>
        </section>
    );
};

export const pageQuery = graphql`
fragment ArticleCardFilter on Contentstack_article_card_filter {
    uid
    heading
    top_line
    sub_title
    single_line
    caption
    first_filter_heading
    second_filter_heading
    reset_button_text
    has_boundary
    is_extended_filter
    show_filter
    number_of_rows
    card_filter_items {
        ...ArticleCardFilterItem
    }
    secondary_filter_items {
        ...ArticleCardFilterItem
    }
}

fragment ArticleCardFilterItem on Contentstack_article_card_filter_item {
    uid
    check_box_text
    query_key
}
`;

export default ArticleCardFilter